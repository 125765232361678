<template>
  <div class="category-form">
      <portlet :title="portletTitle">
        <template v-slot:body>
          <b-form ref="category" @submit.stop.prevent="onSubmit" @reset="onReset">
            <b-form-row>
              <b-col sm="6" md="2">
                <b-form-group id="input-group-is_show" label="ئوچۇقمۇ ؟" label-for="is_show">
                  <b-form-checkbox id="is_show" switch v-model="formData.is_show"/>
                </b-form-group>
              </b-col>
            </b-form-row>
            <b-form-row>
              <b-col sm="6" md="3">
                <b-form-group id="input-group-name" label="ئاپتور ئىسمى" label-for="name">
                  <b-form-input id="name" v-model="formData.name" type="text" :state="validateState('name')" aria-describedby="name-live-feedback" placeholder="ئاپتور ئىسىمنى كىرگۈزۈڭ" />
                  <b-form-invalid-feedback id="name-live-feedback">
                    ئاپتور ئىسمىنى كىرگۈزۈڭ
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
              <b-col sm="6" md="3">
                <b-form-group id="input-group-email" label="ئېلخەت" label-for="email" description="كىيىنكى باشقا ھۆججەت ئەۋەتىش قاتارلىق ئىشلاردا لازىم بولۇپ قېلىشى مۇمكىن">
                  <b-form-input style="direction: ltr" id="email" v-model="formData.email" type="text" placeholder="ئاپتور ئېلخەت ئادېرسىنى كىرگۈزۈڭ، قۇرۇق قويسىڭىزمۇ بولىدۇ" />
                </b-form-group>
              </b-col>
              <b-col sm="6" md="3">
                <b-form-group id="input-group-phone" label="تېلفۇن" label-for="phone" description="كىيىنكى باشقا ئالاقىلىشىش ئىشلاردا لازىم بولۇپ قېلىشى مۇمكىن">
                  <b-form-input style="direction: ltr" id="phone" v-model="formData.phone" type="text" placeholder="ئاپتور تېلفۇن نۇمۇرىنى كىرگۈزۈڭ، قۇرۇق قويسىڭىزمۇ بولىدۇ" />
                </b-form-group>
              </b-col>
              <b-col sm="6" md="3">
                <b-form-group id="input-group-wx" label="ئۈندىدار" label-for="wx" description="كىيىنكى باشقا ئالاقىلىشىش ئىشلاردا لازىم بولۇپ قېلىشى مۇمكىن">
                  <b-form-input style="direction: ltr" id="wx" v-model="formData.wx" type="text" placeholder="ئاپتور ئۈندىدار نۇمۇرىنى كىرگۈزۈڭ، قۇرۇق قويسىڭىزمۇ بولىدۇ" />
                </b-form-group>
              </b-col>
              <b-col sm="6" md="3">
                <b-form-group id="input-group-9" label=" تەرتىبى" label-for="sort" >
                  <b-form-input min="0" style="width: 100%;" id="sort" v-model="formData.sort" type="number" placeholder="ئاپتور تەرتىبىنى كىرگۈزۈڭ، قۇرۇق قويسىڭىزمۇ بولىدۇ" />
                </b-form-group>
              </b-col>
            </b-form-row>
            <b-form-row>
              <b-col sm="6" md="3">
                <b-form-group id="input-group-avatar" label="باش سۆرەت" description="نۆۋەتتىكى ئاپتورنىڭ باش سۆرەت رەسىمىنى تاللاپ يوللاڭ">
                  <image-uploader @success="onUploadSuccess" :img-url="oldImage" :upload-form="{strategy: 'author'}" />
                </b-form-group>
              </b-col>
            </b-form-row>
            <b-form-row>
              <b-col sm="6" md="12">
                <b-form-group id="input-group-desc" label=" قىسقىچە چۈشەنچە" label-for="description" >
                  <b-form-textarea id="description" v-model="formData.description" placeholder="ئاپتور قىسقىچە چۈشەندۈرىلىشىنى كىرگۈزۈڭ" />
                </b-form-group>
              </b-col>
            </b-form-row>
            <b-button id="submit" type="submit" variant="primary">
              ساقلاش
            </b-button>
            <b-button class="ml-2" type="reset" variant="danger">
              بىكار قىلىش
            </b-button>
          </b-form>
        </template>
      </portlet>
  </div>
</template>
<script>
  import formBusyMixin from "../../../mixins/common/formBusy.mixin";
  import Portlet from "../../partials/content/Portlet";
  import ImageUploader from "../../partials/layout/_ImageUploader";
  import vcolorpicker from "vcolorpicker";
  import Vue from 'vue';
  import { validationMixin } from "vuelidate";
  import authorMixin from "../../../mixins/authorMixin";
  import { required } from "vuelidate/lib/validators";
  Vue.use(vcolorpicker);
  import sweetAlertMixin from "../../../mixins/common/sweetAlert.mixin";

  export default {
    name: "authorForm",
    mixins: [ formBusyMixin, validationMixin, authorMixin, sweetAlertMixin ],
    props: {
      action: {
        type: String,
        default: "create"
      }
    },
    validations: {
      formData: {
        name:          { required }
      }
    },
    components: { Portlet, ImageUploader },
    computed: {
      portletTitle(){
        let create = "يېڭىدىن ئاپتور قوشۇش";
        let edit = "ئاپتور ئۇچۇرىنى تەھرىرلەش";
        return this.action === "create" ? create : edit;
      },
      oldImage(){
        return this.action === 'edit' && this.formData.avatar || '';
      },
    },
    created() {
      if ( this.action === 'edit' ){
        let id = this.$route.params.id;
        this.findAuthor(id)
          .then(({data})=>{
            this.formData = data;
          })
          .catch(()=>{
            return this.$router.replace({name: 'authors.list'});
          })
      }
    },
    data(){
      return {
        formData: {
          name: '',
          avatar: '',
          description: '',
          email: '',
          phone: '',
          wx: '',
          is_show: true,
          in_home: true,
          sort: null
        }
      };
    },
    methods: {
      validateState(name, parent = null) {
        if ( parent ){
          const { $dirty, $error } = this.$v.formData[parent][name];
          return $dirty ? !$error : null;
        }
        const { $dirty, $error } = this.$v.formData[name];
        return $dirty ? !$error : null;
      },
      onSubmit(){
        this.$v.formData.$touch();
        if (this.$v.formData.$anyError) {
          this.warning({message:'تولدۇرۇشقا تېگىشلىك جەدىۋەلنى تۇلۇق تولدۇرۇڭ'});
          return;
        }
        if ( this.action === 'create' ){
          this.createAuthor(this.formData)
            .then(()=>{
              return this.$router.replace({name: 'authors.list'});
            })
        }else if ( this.action === 'edit' ){
          this.updateAuthor(this.formData)
            .then(()=>{
              this.success({message: 'تەھرىرلەش مۇۋاپىقىيەتلىك بولدى!'});
            })
        }
      },
      onReset(){
        return this.$router.replace({name: 'authors.list'});
      },
      onUploadSuccess(response){
        this.formData.avatar = response.path;
        this.formData.preview = response.url;
      }
    }
  }
</script>
